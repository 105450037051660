import { Link } from 'gatsby'
import React from 'react'

export const BookBlock = ({title, subtitle, url}) => {
  return (
    <Link className='bg-blue-50 p-4 hover:bg-blue-100' to={url}>
      <h3 className='font-bold text-blue-700'>{title}</h3>
      <span className='text-md text-blue-600'>{subtitle}</span>
    </Link>
  )
}
