import React, { Fragment } from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Divider from "../components/divider.jsx";
import SectionTitle from "../components/sectiontitle.jsx";
import Section from "../components/section.jsx";
// import Subscribe from '../components/subscribe.js';
import Avatar from "../images/avatar.png";
import { BookBlock } from "../components/book-block.jsx";

function IndexPage({ data }) {
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <SEO
        keywords={[
          `Robert Merki`,
          `Robmerki`,
          `Rob Merki`,
          `@robmerki`,
          `Merki.ca`,
        ]}
        noTitle
      />

      {/* About Section */}
      <Section>
        <SectionTitle isH1={false} title="About" />
        <div className="flex">
          <div className="flex-grow">
            <p>
              My name is Robert Merki and I'm best described as a{` `}
              <Link
                className="font-semibold underline"
                to="/blog/what-is-a-wildcard-person"
              >
                Wildcard Person
              </Link>
              .
            </p>
            <p>
              I've worked as a computer systems analyst, front-end developer,
              product designer, customer success manager, VR developer, Director
              of Product, author, and even a{` `}
              <Link
                className="font-semibold underline"
                to="https://www.tiktok.com/@adhdpro.xyz"
              >
                TikTok creator
              </Link>
              .
            </p>
            <p>
              I'm currently working with some amazing humans on
              {` `}
              <Link
                className="font-semibold underline"
                to="https://recordlens.com/"
              >
                RecordLens
              </Link>
              , which builds software for the infrastructure construction
              industry.
            </p>
            <p>I also write books.</p>
          </div>
          <img
            alt="Robert Merki"
            className="h-20 float-right rounded-full mb-2 shadow-inner self-start"
            src={Avatar}
          />
        </div>
      </Section>

      <Divider notop />
      <Section>
        <SectionTitle title="My Books" />
        <div className="my-4 flex flex-col gap-2">
          <BookBlock
            subtitle="Productivity for adults with ADHD"
            title="ADHD Pro (2020)"
            url="https://adhdpro.xyz/"
          />

          <BookBlock
            subtitle="A memoir about love, dating, and masculinity"
            title="The Love Once Promised (WIP)"
            url="https://theloveoncepromised.com/"
          />
        </div>
      </Section>

      <Divider />

      {posts.length >= 1 && (
        <Section>
          <SectionTitle title="Blog Posts" />
          <div className="divide-y divide-slate-100 sm:divide-y-0 border-t border-b sm:border-none border-slate-100 mt-4 ">
            {posts.map(({ node }, i) => {
              const { title, path } = node.frontmatter;
              return (
                <div className="py-2" key={`post_${i}`}>
                  <Link
                    className="inline-block  group text-blue-600 hover:text-blue-700"
                    to={`blog/${path}`}
                  >
                    <span className="hidden sm:inline-block text-lg text-slate-300 ml-2 mr-4">
                      #
                    </span>
                    <span className="group-hover:underline py-2">{title}</span>
                    {i === 0 && (
                      <span className="ml-2 text-sm bg-blue-800 text-white py-1 px-2 rounded-full no-underline">
                        New
                      </span>
                    )}
                  </Link>
                </div>
              );
            })}
          </div>
        </Section>
      )}
    </Layout>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
            path
          }
        }
      }
    }
  }
`;
